// REACT AND GATSBY IMPORTS
import React from 'react';
// STYLING
// CONTAINERS
import UsersLayout from 'gatsby-landing/src/components/UsersLayout';
import WidgetContainer from 'reusecore/src/elements/Widget/WidgetContainer'
// COMPONENTS
import { UserInfoWidget } from 'common/src/components/UserAccountWidgets/UserInfoWidget'
import { LogoutWidget } from 'common/src/components/UserAccountWidgets/LogoutWidget'

// User interface for user account
const UsersAccountPage = () => {
  return (
    <UsersLayout 
      seo={{ title: 'Manage Account' }}
      heading='Manage Account'
    >
      <WidgetContainer>
        <UserInfoWidget />
        <LogoutWidget />
      </WidgetContainer>
    </UsersLayout>
  );
};

export default UsersAccountPage;
